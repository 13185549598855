export const environment = {
  production: true,
  package_app_version: require('../../package.json').build_time,
  api_server_url: 'https://api.fumi.co.kr',
  function_url: `${window.location.hostname}`,
  user_endpoint: 'https://fumi.co.kr',
  android_install: 'https://play.google.com/store/apps/details?id=com.fumi.app',
  call_order_phone: '15880158',
  ios_install: 'https://apps.apple.com/us/app/id1520116997',
  download_onelink: 'https://fumi.co.kr/ad/857',
  kakao: {
    web_key: '5554402ffd337790c4ecc0f6899f4b19',
    app_key: 'ccba2d2cf331e7f4a7fdb0c8f3b3ce0c'
  },
  kakao_pixel: '5527432994105935935',
  import_id: 'imp18105374',
  onesignal: {
    app_id: '990a0b11-b6f8-43ae-a9dd-e74c1e1d8b24',
    sender_id: '611509259962'
  },
  amplitude: '9bbd7df39b7147d226b0d30da1fac125',
  appsflyer_options: {
    devKey: 'Mhumh6xwkLD9XKC5cBcPzQ',
    isDebug: false,
    appID: '1520116997',
    collectAndroidID: true,
    onInstallConversionDataListener: true, // required for get conversion data
    waitForATTUserAuthorization: 10,
    minTimeBetweenSessions: 6, // default 5 sec
    registerOnDeepLink: true,
    registerConversionListener: true,
    registerOnAppOpenAttribution: false,
    deepLinkTimeout: 4000,
    useReceiptValidationSandbox: true, // iOS only
    useUninstallSandbox: true // iOS only
  },
  firebase: {
    apiKey: 'AIzaSyDiXL8AJFpAYdCVl4TG7eyFjtC8M7GX28k',
    authDomain: 'fumi-efluvi.firebaseapp.com',
    databaseURL: 'https://fumi-efluvi.firebaseio.com',
    projectId: 'fumi-efluvi',
    storageBucket: 'fumi-efluvi.appspot.com',
    messagingSenderId: '611509259962',
    appId: '1:611509259962:web:7e8a6a8fdcea08c23a7c83',
    measurementId: 'G-V514LM9CXZ'
  },
  delivery_url: 'https://delivery.fumi.co.kr',

  youtube_api_key: 'AIzaSyBXslm83-hyYhZZovQEw-rMZf1j8RJGJVU',
  enable_liveupdate: true,
  show_login_proxy: false,
  is_app: true,
  is_native_portal: false,
  no_img_url: 'https://fumi-cdn.fumi.co.kr/resource/no_image.png',
  default_fumist_banner: 'https://fumi-cdn.fumi.co.kr/resource/default_fumist_banner.png',
  aws_s3: {
    s3: {
      credentials: {
        accessKeyId: 'AKIASMRBQHXI3QK7ZFOJ',
        secretAccessKey: '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'
      },
      region: 'ap-northeast-2'
    },
    bucket: 'efluvi-storage',
    container: 'user_upload',
    image_cdn: 'https://fumi-cdn.fumi.co.kr',
    file_cdn: 'https://file.fumi.co.kr'
  },
  tooning_url: 'https://tooning.io',
  channel: 'fumi'
};
